<template>
  <docs-layout :show-back-link="false" class="pb-10 space-y-4">
    <h1 class="text-3xl font-bold">Components</h1>
    <div class="w-full">
      <table class="table-auto w-full">
        <caption class="hidden"></caption>
        <thead>
          <tr>
            <th
              scope="col"
              class="border border-light-blue-500 px-4 py-2 text-light-blue-600"
            >
              No
            </th>
            <th
              scope="col"
              class="border border-light-blue-500 w-1/4 px-4 py-2 text-light-blue-600"
            >
              Component
            </th>
            <th
              scope="col"
              class="border border-light-blue-500 w-1/4 px-4 py-2 text-light-blue-600"
            >
              Status
            </th>
            <th
              scope="col"
              class="border border-light-blue-500 w-1/2 px-4 py-2 text-light-blue-600"
            >
              Comments
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(component, index) in componentList" :key="index">
            <td
              class="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium"
            >
              {{ index + 1 }}
            </td>
            <td
              class="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium"
            >
              <router-link
                :to="`/docs/${component.path}`"
                v-slot="{ href, navigate }"
              >
                <asom-link :href="href" @click="navigate">
                  {{ component.title }}
                </asom-link>
              </router-link>
            </td>
            <td
              class="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium"
            >
              <asom-badge :variant="component.done ? 'success' : 'secondary'">
                {{ component.done ? "Done" : "In progress" }}
              </asom-badge>
            </td>
            <td
              class="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium"
            >
              {{ component.comments }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h1 class="text-3xl font-bold">Design</h1>
    <div class="w-full">
      <table class="table-auto w-full">
        <caption class="hidden"></caption>
        <thead>
          <tr>
            <th
              scope="col"
              class="border border-light-blue-500 px-4 py-2 text-light-blue-600"
            >
              No
            </th>
            <th
              scope="col"
              class="border border-light-blue-500 w-1/4 px-4 py-2 text-light-blue-600"
            >
              Template
            </th>
            <th
              scope="col"
              class="border border-light-blue-500 w-1/4 px-4 py-2 text-light-blue-600"
            >
              Integration
            </th>
            <th
              scope="col"
              class="border border-light-blue-500 w-full px-4 py-2 text-light-blue-600"
            >
              Comments
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(template, index) in templateList" :key="index">
            <td
              class="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium"
            >
              {{ index + 1 }}
            </td>
            <td
              class="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium"
            >
              <router-link :to="template.path" v-slot="{ href, navigate }">
                <asom-link :href="href" @click="navigate">
                  {{ template.name }}
                </asom-link>
              </router-link>
            </td>
            <td
              class="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium"
            >
              <router-link
                v-if="template.integratePath"
                :to="template.integratePath"
                v-slot="{ href, navigate }"
              >
                <asom-link :href="href" @click="navigate">
                  {{ template.integrateName }}
                </asom-link>
              </router-link>
            </td>
            <td
              class="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium"
            >
              {{ template.comments }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="inline-block mr-2 mt-2">
      <button
        @click="getDeviceType"
        type="button"
        class="focus:outline-none text-white text-sm py-2.5 px-5 rounded-md bg-blue-500 hover:bg-blue-600 hover:shadow-lg"
      >
        Show Device Type
      </button>
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from "@/docs/DocsLayout.vue";

export default {
  name: "DocsIndex",
  components: {
    DocsLayout,
  },
  methods: {
    getDeviceType() {
      var nAgt = navigator.userAgent;
      var os = null;
      var clientStrings = [
        { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
        { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
        { s: "Windows Vista", r: /Windows NT 6.0/ },
        { s: "Windows Server 2003", r: /Windows NT 5.2/ },
        { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
        { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
        { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
        { s: "Windows 98", r: /(Windows 98|Win98)/ },
        { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
        {
          s: "Windows NT 4.0",
          r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
        },
        { s: "Windows CE", r: /Windows CE/ },
        { s: "Windows 3.11", r: /Win16/ },
        { s: "Android", r: /Android/ },
        { s: "Open BSD", r: /OpenBSD/ },
        { s: "Sun OS", r: /SunOS/ },
        { s: "Chrome OS", r: /CrOS/ },
        { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
        { s: "iOS", r: /(iPhone|iPad|iPod)/ },
        { s: "Mac OS X", r: /Mac OS X/ },
        { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: "QNX", r: /QNX/ },
        { s: "UNIX", r: /UNIX/ },
        { s: "BeOS", r: /BeOS/ },
        { s: "OS/2", r: /OS\/2/ },
        {
          s: "Search Bot",
          r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
        },
      ];
      for (var id in clientStrings) {
        var cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
          os = cs.s;
          break;
        }
      }
      alert("System OS: " + os);
    },
  },
  computed: {
    componentList() {
      return [
        { path: "typography", title: "Typography", done: true },
        { path: "icon", title: "Icon (svg)", done: true },
        { path: "container", title: "Container", done: true },
        { path: "grid_system", title: "Grid system Documentation", done: true },
        { path: "card", title: "Card", done: true },
        { path: "button", title: "Button", done: true },
        { path: "link", title: "Link", done: true },
        { path: "list", title: "List", done: false },
        { path: "breadcrumb", title: "Breadcrumb", done: true },
        { path: "accordion", title: "Accordion", done: false },
        { path: "pagination", title: "Pagination", done: true },
        { path: "modal", title: "Modal", done: true },
        { path: "alert", title: "Alert", done: true },
        { path: "notification", title: "Notification (popup)", done: false },
        {
          path: "dropdown",
          title: "Dropdown",
          done: true,
          comments: "Use popper.js for dynamic position",
        },
        { path: "tab", title: "Tabs", done: false },
        { path: "tooltip", title: "Tooltip", done: false },
        { path: "badge", title: "Badge", done: true },
        { path: "spinner", title: "Spinner and loading overlay", done: false },
        { path: "progress", title: "Progress Bar", done: false },
        { path: "input_text", title: "Input Text", done: true },
        { path: "input_textarea", title: "Input Textarea", done: true },
        // { path: 'input_checkbox', title: 'Input Checkbox', done: false },
        // { path: 'input_radio', title: 'Input Radio', done: false },
        // { path: 'input_select', title: 'Input Select', done: false },
        // { path: 'input_multiselect', title: 'Input Multi Select', done: false },
        { path: "input_checkbox", title: "Input Checkbox", done: true },
        { path: "input_radio", title: "Input Radio", done: true },
        { path: "input_select", title: "Input Select", done: true },
        { path: "input_multi_select", title: "Input Multi Select", done: true },
        {
          path: "input_date",
          title: "Input Date Picker",
          done: true,
          comments: "Use v-calendar@next",
        },
        { path: "input_upload", title: "Input File Upload", done: true },
        { path: "input_toggle", title: "Input Toggle", done: false },
        { path: "form_display", title: "Form Display", done: true },
        {
          path: "client-table",
          title: "Client Table",
          done: true,
          comments: "improve sorting and filtering logic",
        },
        { path: "server-table", title: "Server Table", done: false },
        { path: "navbar", title: "Navbar", done: true },
        {
          path: "schedule",
          title: "Schedule",
          done: true,
          comments: "Just a test",
        },
        { path: "slider_menu", title: "Slider Menu", done: true },
        { path: "signature_pad", title: "Signature Pad", done: true },
        { path: "bar_code_scanner", title: "Barcode Scanner", done: true },
        { path: "qr_code_scanner", title: "QR Code Scanner", done: true },
        { path: "human_diagram", title: "Human Diagram Input", done: true },
        { path: "input_multi_email", title: "Input Multi Email", done: true },
      ];
    },
    templateList() {
      return [
        {
          path: "/docs/design/example",
          name: "Example",
          comments: "Random comment",
        },
        {
          path: "/docs/design/new_dashboard_layout",
          name: "New Dashboard Layout",
          integratePath: "/docs/integrate_design/layout",
          integrateName: "Layout",
          comments: "Random comment",
        },
        {
          path: "/docs/design/left_nav_second_level",
          name: "Left Nav Second Level",
          comments: "Random comment",
        },
        {
          path: "/docs/design/roster_list_view",
          name: "Roster List View",
          comments: "Random comment",
        },
        {
          path: "/docs/design/roster_list_view_personal_cycle",
          name: "Roster List View Personal - Cycle",
          comments: "Random comment",
        },
        {
          path: "/docs/design/roster_list_view_personal_week",
          name: "Roster List View Personal - Week",
          comments: "Random comment",
        },
        {
          path: "/docs/design/new_login_screen",
          name: "New Login Screen",
          comments: "Random comment",
        },
        {
          path: "/docs/design/station_cash_management_overview",
          name: "Station Cash Mnagement Overview",
          comments: "Cash Management",
        },
        {
          path: "/docs/design/psc",
          name: "PSC",
          comments: "Cash Management",
        },
      ];
    },
  },
};
</script>
